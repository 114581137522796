import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { WrapperService } from "./shared/services/wrapper/wrapper.service";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

    version:string = '1.2.8v';
    erroCaps: boolean;
    newVersion: any;

    constructor(
        private http: HttpClient,
        private wrapper: WrapperService,
        private db: AngularFirestore
    ) {
        if ((window as any).mobileToken) {
            (window as any).open = (link, _) => {
                window.parent.postMessage({url: link}, '*');
            };
        }
    }

    ngOnInit() { 
        this.db.collection(('versao')).doc('versao').get().subscribe((item) => {
            let resp:any = item.data();

            if (this.version == resp.versao) {
                console.log('certo');
                this.erroCaps = false;
            } else {
                console.log('errado');
                this.newVersion = resp.versao;
                this.erroCaps = true;
            }
             
            console.log(item.data());
        });

     }
}
