import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WrapperService } from "../wrapper/wrapper.service";
import { environment as env } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userList: any;
  userExist: string;
  fireUser: any = undefined;
  userData: any = undefined;
  usuarios: any[] = [];

  constructor(
        private messageService: MessageService,
        private afAuth: AngularFireAuth,
        private wrapper: WrapperService,
        private http: HttpClient) {}

  //Firebase
  currentUser() {
    return this.afAuth.authState.pipe(first()).toPromise();;
  }

  async getUserToken() {
    let token = await this.afAuth.idToken.pipe(first()).toPromise();
    return token;
  }

  //API
  createUser(usuario): Observable<any>{
    usuario.menus = JSON.stringify(usuario.menus);
    return this.http.post<any>(this.wrapper.getUrl('user', `${(env.production ? '' : 'base=test')}`, 'POST'), usuario)
  }

  updateUser(usuario): Observable<any>{
    usuario.menus = JSON.stringify(usuario.menus);
    usuario.submenus = JSON.stringify(usuario.submenus);
    return this.http.put<any>(this.wrapper.getUrl(`user/update`, `${(env.production ? '' : 'base=test')}`, 'PUT'), usuario);
  }

  getAllUser(): Observable<any[]>{
    return this.http.get<any[]>(this.wrapper.getUrl(`user`, `${(env.production ? '' : 'base=test')}`, 'GET'))
  }

  getByEmail(usuario): Observable<any>{
    return this.http.get<any>(this.wrapper.getUrl(`user/${usuario.email}`, `${(env.production ? '' : 'base=test')}`, 'GET'), usuario)
  }

  async getCurrentUserData() {
    let firebaseData = await this.currentUser();
    let userData = await this.getByEmail(firebaseData).toPromise();
    return userData[0];
  }

  //NOVO
  async newUser(usuario) {

        !confirm(`Os dados de acesso serão enviado ao e-mail informado, deseja continuar?`)
        await this.createUser(usuario).toPromise();
        let token = await this.getUserToken();
        let customHeaders = new Headers();
        customHeaders.append('Content-Type', "application/json");
        const headers = new HttpHeaders({'Content-Type': "application/json"});
        let userData = {"newUserEmail": usuario.email, 'newUserName': usuario.email};
        await this.http.post(`https://rk7ml9blud.execute-api.us-east-1.amazonaws.com/default/CICAMPO-createUser?auth=${token}`, userData, {headers}).toPromise();
        this.message('Usuário criado com sucesso!', 'success', 'Sucesso', 3000);
        return true;

  }

  //Verifica
  async verifUser(usuario) {
    this.getByEmail(usuario).subscribe(async(resp) =>{
      this.userExist = resp[0]
      if(this.userExist === undefined){
        await this.newUser(usuario);
        return true;
      } else {
        this.message('Já existe um usuário criado este e-mail!');
        return false;
      }
    });

  }

  //Util
  convertObjToArray(obj) {
    let objs = JSON.parse(obj['_body']);
    if (obj && objs) {
      return Object.keys(objs).map( (item) => {
              let data = objs[item];
              data['id'] = item;
              return data;
            });
    }
    return [];
  }
  delete(data) {
    return this.http.post<any[]>(this.wrapper.getUrl(`user/delete`, `${(env.production ? '' : 'base=test')}`, 'POST'), data)  
  }

  message(msg, type = 'warn', summary = 'Atenção', time = 5000) {
    this.messageService.add({severity:type, summary: summary, detail:msg, life: time});
  }

}
